* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html {
    scroll-behavior: smooth;
  }

  @font-face {
    font-family: "Gothic";   /*Can be any text*/
    src: local("CenturyGothic"),
      url("./fonts/Century Gothic.ttf") format("truetype");
  }
