.news{
  height: 100vh;
  padding-top: 80px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row{
  width: 100%;
  display:flex;
  flex-direction: row;
  align-items: center;
  margin-top: -80px;
}

.news h1 {
  font-size: 5em;
  color: black;
  font-family: 'League Gothic', sans-serif;
}

.news .text{
  padding: 30px;

}

.text h1{
  margin-top: -20%;
  margin-bottom: 40px;
}

.news .text p {
  color: black;
  font-size: 1.2em;
  text-align: center;
  width: 100%;
  margin: auto;
  font-family: "Gothic";
}

.news .text h3, .latest h3 {
  color: black;
  font-family: 'League Gothic', sans-serif;
  font-size: 2.9em;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}


.news-product img{
 padding: 20px;
width: 100%;
height:auto;
}

.news-product{
  flex: 1 1 630px;
}



.latest{
padding: 30px;
  text-align: center;
}



.news .text {
  width: 70%;
  text-align: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.latest span{
  margin-bottom: 20px;
  cursor: pointer;
}

.latest span img{
  margin-bottom: 10px;
}

@media only screen and (max-width: 700px) {
 .news{
  height: auto;
  width: 100vw;
 }

 .row{
  flex-direction: column;
  padding:20px;
 }

 .news-product{
  margin-top: 60px;
 }

 .news-product img{
  width: 100%;
 }

 .news .text{
  width: 100%;
  padding: 0px;
 }

 .news .text h3{
  font-size: 2em;
 }

 .news h1{
  margin-top: 30px;
 }

 .text h1{
  position: absolute;
  top: 70px;
  width: 90%;
  text-align: center;
 }

 .news-product{
  flex: none;
  margin-top: 35%;
 }
}
  @media only screen and (min-width: 701px) and (max-width: 1400px) {
    .news{
      height: auto;
      width: 100vw;
     }
    
     .row{
      flex-direction: column;
      padding: 30px;
     }
    
     .news-product{
      margin-top: 60px;
     }
    
     .news-product img{
      width: 100%;
     }


 .text h1{
  position: absolute;
  top: 10%;
  width: 100%;
  text-align: center;
  margin:0;
  left:0;
 }

 .news-product{
  flex: none;
  margin-top:20%;
 }

 .news .text{
width: 90%;  
 }

  }



  @media only screen and (width: 1024px) and (height: 1366px) {
    .news  .news-product {
      height: 60%;
    }
    .text h1 {
      top:10%;
    }

  }

  @media only screen and (min-width: 1424px) and (max-height: 900px ) {
    .news h1, .latest{
      margin-top: 80px;
     }

     .news{
      /* height:auto; */
      min-height: 60vh;
     }
  }
/* zoom 150% */
  @media only screen and (min-width: 1128px) and (max-height: 1013px ) {
.text h1{
  margin-top:10%;
}
  }
/* nest hub */
  @media only screen and (min-width: 1024px) and (max-height: 900px ) {
.text h1{
  top: 20%;
}
  }

  @media only screen and (min-device-width: 1180px) and (max-device-height: 820px ) {
    .text h1{
      top: 20% ;
    }
      }

