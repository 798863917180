.categories {
    background-image: url("../../assets/17.jpg");
    background-size: cover;
    /* height: auto; */
    padding-bottom:20px;
}

.half {
    width: 50%;
    height: 100%;
    margin-left: 50%;
    align-items: center;
    justify-content: space-evenly;
}

.half, span{
    display: flex;
    flex-direction: column;
}

span{
    justify-content: center;
    width: 100%;
    align-items: center;
}
.half .link{
    text-decoration: none;
    color: white;
}
.categories > .half> span >h1 {
    font-size: 102px;
    color: white;
    font-family: 'League Gothic', sans-serif;
}
.categories ul {
    width: 100%;
}
.categories ul li {
    list-style-type: none;
    text-decoration: none;
    padding: 10px ;
    cursor: pointer;
    width: 100%;
}

.categories ul .link li:hover{
    color: grey;
}

span ul {
    /* display: inline-block; */
    text-align: center;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 21px;
    font-weight: 700;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

p {
    font-size: 20px;
    color: white;
    width: 70%;
    text-align: center;
    font-weight: bold;
    font-family: "Gothic";
}

.catrender ul{
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}
.half ul{
    flex-direction: column;
}

@media only screen and (max-width: 700px) {

    .half{
        width: 100%;
        margin-left: 0;
    }

    .categories{
        background-position: 95%;
    }

    .categories .half h1{
        font-size: 4.5em !important;
    }

    .categories ul li {
        padding: 10px;
        font-size: 1em;
    }

    .catrender ul{
        width: 80%;
    }

}

@media only screen and (min-width: 701px) and (max-width: 1400px) {
    .half{
        width: 100%;
        margin-left: 0;
    }

    .categories{
        background-position: 95%;
    }
}

@media only screen and (max-height: 440px){
.categories{
    height: auto;
}
}