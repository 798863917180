.about{
    height: 100dvh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about> h1{
    color: black;
    font-size: 102px;
    font-family: 'League Gothic', sans-serif;
}

.about> p{
    font-size: 21px;
    color: black;
    width: 60%;
    text-align: center;
    line-height: 2;
    font-family: "Gothic";
}

.properties{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 50px;
}

.properties div img{
   border-radius: 50%;
   margin-top: 20px;
}

.properties div{
    text-align: center;
}

@media only screen and (max-width: 700px) {

    .about {
        width: 100%;
    }
    .about>h1{
        font-size: 5em;
    }

    .about>p,
    .about>h1 {
        width: 80%;
        text-align: center;
    }

    .about>p{
        font-size: 1.2em;
    }

    .properties{
        display: none;
    }

}

@media only screen and (max-width: 1400px) and (max-height: 630px){
   .about{
      height: auto;
    }
  }


@media only screen and (max-height: 440px){
.about{
    height: auto;
}
}