footer{
    height: auto;
    padding : 40px 0;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

footer>p{
    /* font-family: 'Lora', serif; */
    font-size: 18px;
    font-family: "Gothic";
}

footer>p>a{
    text-decoration: none;
    color: white;
}