.notfound > h1 {
    color: white;
    font-family: 'League Gothic', sans-serif;
    font-size: 63px;
}

.notfound {
    background-image: url("../../assets/18.jpg");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
}