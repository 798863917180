.catrender {
  /* background-image: url("../../../assets/fons4.jpg"); */
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  flex-direction: column;
  background-position-x:left;
}

.catrender>h1 {
  font-size: 5em;
  color: black;
  font-family: 'League Gothic', sans-serif;

}

.catrender .link{
  color: black;
text-decoration: none;
}


.slideshow-container {
  width: 80%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  height: 70vh;
}

.catrender .slideshow-container .product {
  width: 50%;
  height: 90%;
  display: flex;
  justify-content: center;
  margin-top: -0px;
}

.slideshow-container .product img {
  z-index: 2;
}

.prev,
.next {
  content: url("../../../assets/arrow2.png");
  position: absolute;
  cursor: pointer;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  width: 60px;
  top: 60%;
  transition: 0.6s ease;
  z-index: 999;

}

.prev {
  transform: rotate(270deg);
  left: 5%;
}

.next {
  transform: rotate(90deg);
  right: 5%;
}


.catrender .text {
  width: 60%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.catrender .text p {
  color: black;
  font-size: 1.5em;
  text-align: center;
  width: 100%;
  margin: auto;
  font-family: "Gothic";
}

.catrender .text h3 {
  color: black;
  font-family: 'League Gothic', sans-serif;
  font-size: 2.9em;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}

.swipe{
  display: none;
}

.noshow{
  display: none;
}
.catrender span ul li{
display: inline;
padding: 5px;
z-index: 4;
position: relative;
}

.catrender span {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  width: auto;
  padding: 6px;
}


.selected{
  display: none;
}

.mobilecount{
  display: none;
}

.catrender button{
  background-color: transparent;
  border: 1px solid black;
  width:150px;
  color: black;
}

@media only screen and (max-width: 700px) {
  .catrender {
    background-position: 95%;
    width: 100vw;
    height: auto;
  }

  span> ul>.link {
    width:auto;
font-size: 18px;
  }

  .catrender h1 {
    font-size: 4em;
    margin-top: 100px;
  }

  .slideshow-container {
    width: 100%;
    flex-direction: column;
    height: auto;
    overflow-x: auto;
  }

  .catrender .slideshow-container .product {
    height: 30%;
    margin: 0;
    justify-content: center;
  }

  .slideshow-container img {
    height: 300px;
    margin-top: 10px;
  }

  .mobilecount{
    display:block;
    color: black;
    font-family: "Gothic";
  }

  .catrender .text {
    height: auto;
    width: 90%;
    margin-bottom: 40px;
    padding: 0;
    margin-left: 0;
    margin-top: 0;
  }

  .text p,
  .text h3 {
    text-align: center;
  }

  .prev,
  .next {
    display: none;
  }


  .swipe{
    display: block;
    position: absolute;
    top: 50%;
   align-items: start;
    color: black;
    animation: cursor 3s infinite;
z-index: 4;
font-size:50px;
transform: rotate(3.1416rad);
}

  @keyframes cursor {
    0% {
      left: 80%;
    }

    100% {
      left: 40px;
    }
  }

  .prev {
    left: 0;
  }

  .next {
    right: 4%;
  }

}



@media only screen and (min-width: 701px) and (max-width: 1400px) {
  .catrender {
    background-position: 100%;
    width: 100vw;
  }

}


  @media only screen and  (min-width: 1000px) and (max-height: 660px){
  .catrender{
    height: 115dvh;
  }
}   

@media only screen and (min-width: 710px) and (max-width: 1030px){
  .catrender{
    height: 100dvh;
  }

  .catrender {
    background-position: 95%;
    width: 100vw;
    height: auto;
  }

  .catrender h1 {
    margin-top: 100px;
  }

  .catrender .slideshow-container {
    width: 100%;
    flex-direction: column;
    height: auto;
    overflow-x: auto;
  }

  .catrender .slideshow-container .product {
    height: 30%;
    margin: 0;
    scale: 0.9
  }

  .text {
    height: auto;
    width: 90%;
    margin-bottom: 40px;
    padding: 0;

  }

  .prev,
  .next {
    display: none;
  }


  .swipe{
    display: block;
    position: absolute;
    top: 50%;
   align-items: start;
    color: black;
    animation: cursor 3s infinite;
z-index: 4;
font-size:50px;
transform: rotate(3.1416rad);
}
}