nav {
    width: 100%;
    background-color: white;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 40px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    position: fixed;
    z-index: 999;
    align-items: center;
}
nav>div{
    display:flex;
    flex-direction: row;
}
nav>span>a>picture>img {
    width: 180px;
    margin-right: 10px;
}

.soc{
    display:flex;
    flex-direction: row;
    justify-content: center;
    width: auto;
}

.soc a{
    margin-right: 5px;
}

ul,
nav {
    display: flex;
}

nav>div>ul {
justify-content: flex-end;
}
nav>div>ul>li{
    margin-right:40px;
}

nav>div> ul>li>a {
    text-decoration: none;
    color: black;
}

nav>div>ul>li {
    list-style: none;
    display: inline;
}

nav>div>ul>li>a:hover {
color: grey;
}


.dropdown {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 35px;
    border: 1px sold black;
}

.options {
    position: absolute;
    right: 20px;
    top: 55px;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    width: 65px;
}

.options>ol {
    display: block;
}

.options>ol>li {
    list-style-type: none;
    cursor: pointer;
    margin-bottom: 5px;
}

.options>ol>li:hover {
    color: grey;
}

.arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-top: 5px;
}

.selected{
    color: grey;
}

i {
    margin-top: -5px;
}

div>.mobile, div>.close {
    display: none;
}

div> .close{
    display: none;
}


@media only screen and (max-width: 940px) {

    nav {
        height: auto;
    }

    nav>div>ul {
        display: none;
    }

    nav>div>ul>li {
        margin-right: 0;
    }

    nav>span>a>picture>img{
        width: 42px;
    }
    

    .dropdown {
        display: none;
    }

    div.mobile {
        display: block;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

    }

    .line {

        width: 25px;
        height: 3px;
        background-color: #3f3f3f;
        margin: 5px auto;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        display: block;
    }

    

    div.close {
        display: block;
        height: 40px;
        width: 40px;
        overflow: hidden;
        padding-top: 8px;
    }

    .close .line:nth-child(2) {
        opacity: 0;
    }

    .close .line:nth-child(1) {
        -webkit-transform: translateY(8px) rotate(45deg);
        -ms-transform: translateY(8px) rotate(45deg);
        -o-transform: translateY(8px) rotate(45deg);
        transform: translateY(8px) rotate(45deg);
    }

    .close .line:nth-child(3) {
        -webkit-transform: translateY(-8px) rotate(-45deg);
        -ms-transform: translateY(-8px) rotate(-45deg);
        -o-transform: translateY(-8px) rotate(-45deg);
        transform: translateY(-8px) rotate(-45deg);
    }

 
    .mobilelist {
        position: fixed;
        width: 100%;
        left: 0;
        top: 80px;
        background-color: white;
        display: block;
       padding: 50px 30px 30px 20px;
        display: flex;
        flex-direction: column;
        height: auto;
justify-content: center;
align-items: center;
box-shadow: 0 2px 10px -6px rgb(0 0 0 / 0.2);
overflow: scroll;
height:auto;
    }


    .mobilelist>li>a {
        display: block;
        padding: 10px;
        font-size: 1.2em;

    }

    .options {
        width: 100%;
        text-align: center;
        font-size: 1em;
        display: block;
        background-color:transparent;
        right: 0;
        margin-top:20px;
    }

    .options>ol>li {
        display: inline;
        margin: 5px;
        font-size: 1.2em;
    }


}

@media only screen and (max-width: 1200px) {
    nav>span>a>picture>img{
        width: 42px;
    }
}
