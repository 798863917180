

.home {
    height: 100dvh;
    background-image: url("../../assets/16.jpg");
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80%;
    margin-top: 60px;
}


.home>.flex>h1 {
    font-size: 102px;
    color: white;
    font-family: 'League Gothic', sans-serif;
    text-align: center;
}

.home>.flex>p {
    font-size: 21px;
    color: white;
    font-family: "Gothic";
}

.home>span{
position: sticky;
margin-top: auto;
}

@media only screen and (max-width: 700px) {

    .home {
        width: 100%;
    }

    .home>.flex>h1{
        font-size: 3em;
    }

    .home>.flex>p,
    .home>.flex>h1 {
        width: 80%;
        text-align: center;
    }

    .home>p{
        font-size: 1.5em;
    }


}

@media only screen and (max-height: 440px) {
    .home{
        height: 150vh;
    }

    .home>.flex>h1{
        font-size: 75px;
    }
}
