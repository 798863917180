.part {
  height: auto;
  padding: 40px 0;
}

.part h1 {
  color: black;
  font-size: 102px;
  text-align: center;
  margin-bottom: 40px;
}

.partners {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: auto;
  flex-wrap: wrap;
  row-gap: 6px;
  column-gap: 3em;
  align-items: center;
  width: 100%;
  background-color: white;
  padding: 20px;
}

.partners img{
  margin-right: 15px;

}

.partners > a{
  flex: 0 0 8.11%;
    display: flex;
    justify-content: center;
}


@media only screen and (max-width: 700px) {


  .partners{
    height: auto;
    row-gap: 5px;
    column-gap: 0;
  }

  .partners img {
    width: 20px;
    height: auto;
    margin-right: 10px;
  }
  .partners>a{
    flex: 0 0 16.6%;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 701px) and (max-width: 1400px) {
  .partners img {
    width: 50px;
}
.partners{
  height:auto;
  column-gap: 0em;
}

.partners>a{
  flex: 0 0 16.6%;
  display: flex;
  justify-content: center;
}
}


@media only screen and (min-width: 390px) and (max-width: 700px){
  .partners img {
    width: 40px !important;
  }

  .partners{
    flex-direction: row;
  }
} 

@media only screen and (min-width: 1401px) and (max-width: 1460px) {
  .partners>a{
    flex: 0 0 13.3%;
  }
}
