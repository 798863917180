section {
    height: 103dvh;
}

.agree {
    background-color:hsl(300, 2%, 8%, 60%) ;
    width: 100%;
    top: 0;
    position: fixed;
    z-index: 99999;
}

.agree,
.wrapper,
.age {
    display: flex;
    align-items: center;
}

.agree,
.wrapper {
    justify-content: center;
}

.wrapper {
    background-color: white;
    height: 250px;
    width: 35%;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 700;
    padding: 10px;
}

.approve,
.cancel {
    background-color: white;
    border: 1px solid black;
    color: black;
}


button {
    border: none;
    padding: 10px;
    width: 90px;
    margin-top: 20px;
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
}

.age {
    justify-content: space-evenly;
    flex-direction: row;
    width: 50%;
}

@media only screen and (max-width: 720px) {
    .wrapper{
        width: 90%;
        font-size: 1em;
    }

    .age{
        width: 80%;
    }
}

@media only screen and (min-width: 701px) and (max-width: 1400px) {
    .wrapper{
        width: 50%;
    }
}