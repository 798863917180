.contacts{
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contacts>h1{
    color: black;
    font-size: 102px;
    font-family: 'League Gothic', sans-serif;
}

.contacts>p{
    font-size: 21px;
    color: black;
    width: 60%;
    text-align: center;
    margin-bottom: 30px;
    font-family: "Gothic";
}
a{
  color: black;
}



span h3 {
    color: black;
    font-family: 'League Gothic', sans-serif;
    font-size: 2.5em;
    margin-bottom: 20px;
    margin-top: 40px;
    width: 100%;
    text-align: center;
  }

@media only screen and (max-width: 720px) {

    .contacts {
        width: 100%;
    }
    .contacts>h1{
        font-size: 5em;
    }

    .contacts>p,
    .contacts>h1 {
        width: 80%;
        text-align: center;
    }

    .contacts>p{
        font-size: 1.5em;
    }

}

@media only screen and (max-width: 1400px) and (max-height: 630px){
    .contacts{
      height: 130dvh;
    }
  }

  @media only screen and (max-height: 440px) {
    .contacts{
        height: auto;
    }
}